/**
 * 节流
 * func 要执行的回调函数 
 * wait 延时的时间
 */
let timer, flag;
export const throttle = (func, wait = 1000) => {
	if (!flag) {
		flag = true
		// 如果是非立即执行，则在wait毫秒内的结束处执行
		timer = setTimeout(() => {
			flag = false
			typeof func === 'function' && func();
		}, wait);
	}
}

/**
 * 防抖
 * func 要执行的回调函数 
 * wait 延时的时间
 */
let timeout = null;
export const debounce = (func, wait = 1000) => {
	// 清除定时器
	if (timeout !== null) clearTimeout(timeout);
	// 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
	timeout = setTimeout(function () {
		typeof func === 'function' && func();
	}, wait);
}

/**
 * 时间格式化
 * date 时间函数 new Date()
 * fmt 时间格式 yyyy-MM-dd HH:mm:ss
 */
export const format = (date, fmt) => {
	var o = {
		"M+": date.getMonth() + 1, // 月份
		"d+": date.getDate(), // 日
		"H+": date.getHours(), // 时
		"m+": date.getMinutes(), // 分
		"s+": date.getSeconds(), // 秒
		"q+": Math.floor((date.getMonth() + 3) / 3), // 季度
		"S": date.getMilliseconds() // 毫秒
	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k])
			.substr(("" + o[k]).length)));
	return fmt;
}
// 按钮返回code的函数  code -- 按钮的code值
export const codeFn = (code) => {
	let powerListArr = JSON.parse(localStorage.getItem("powerListArr"));
	let newCode = ""
	powerListArr.map(item => {
		item.children.forEach(i => {
			if (i.code == code) {
				newCode = code;
			}
			i.children.forEach(el => {
				if (el.code == code) {
					newCode = code;
				}
			})
		})
	})
	return newCode;
}